const slugify = require('slugify');

const pad = (n, zeros) => {
    let s = "00000000" + String(n);
    return s.substr(s.length - zeros, zeros);
}

exports.getYouTubeVideos = function(text) {
    let wv = text.match(/http(?:s)?:\/\/(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_]{1,40})/igm),
        ev = text.match(/http(?:s)?:\/\/(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_]{1,40})/igm);
    
    return (wv || []).concat(ev || []);
}

exports.textFixes = function(text) {
    if (typeof text !== "string") { return text; }

    let r = text.replace(/\n/igm, "");
    r = r.replace(/<br \/><\/em>/igm, "</em><br />");
    r = r.replace(/<br \/><\/strong>/igm, "</strong><br />");
    r = r.replace(//igm, "");
    r = r.replace(//igm, "");
    r = r.replace(//igm, "");
    return r;
}

exports.fixTitle = function(text) {
    return text
        .replace(/“/igm, '"').replace(/”/igm, '"')
        .replace(/"/igm, '\\"');
}

const createSlug = function(text) {
    return slugify(text, { strict: true, lower: true });   
}
exports.createSlug = createSlug;

exports.formatDate = function(dtIsoString, lang, addTime = true) {
    let time, months;
    const dateFormat = {
        "en": function(date) {
                const dayFn = day => {
                    let du = day % 10,
                        dd = Math.floor(day / 10);
                    
                    if (dd !== 1 && du === 1) {
                        return day + "st";
                    }

                    if (dd !== 1 && du === 2) {
                        return day + "nd";
                    }

                    if (dd !== 1 && du === 3) {
                        return day + "rd";
                    }

                    return day + "th";
                };
                
                months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

                let str = `${months[date.getMonth()]} ${dayFn(date.getDate())}, ${date.getFullYear()}`;

                if (!addTime) { return str;}

                time = `, ${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}`;
                return str + time;
            },
        "es": function(date) {

            months = [ "ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic" ];
            let str = `${date.getDate()}/${months[date.getMonth()]}/${date.getFullYear()}`;

            if (!addTime) { return str;}

            time = `, ${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}`;
            return str + time;

        }
    }

    return lang === "en" ?
        dateFormat.en(new Date(dtIsoString))
        : dateFormat.es(new Date(dtIsoString));
}

exports.createMetaDesc = function(str) {

    const strippedString = str.replace(/(<([^>]+)>)/gi, "")
        .replace(/\n/gi, "")
        .replace(/\s{2,20}/gi, "")
        .replace(/&ntilde;/gm, "ñ")
        .replace(/&Ntilde;/gm, "Ñ")
        .replace(/&aacute;/gm, "á")
        .replace(/&eacute;/gm, "é")
        .replace(/&iacute;/gm, "í")
        .replace(/&oacute;/gm, "ó")
        .replace(/&uacute;/gm, "ú")
        .replace(/&Aacute;/gm, "Á")
        .replace(/&Eacute;/gm, "É")
        .replace(/&Iacute;/gm, "Í")
        .replace(/&Oacute;/gm, "Ó")
        .replace(/&Uacute;/gm, "Ú")
        .replace(/&iquest;/gm, "¿")
        .replace(/"/gm, "&quot;");

    return strippedString;

};

exports.getIdFromGuid = function(guid) {
    const params = guid.split("?").pop().split("&"),
        argsByName = {};

    params.forEach(prm => {
        let ps = prm.split("=");
        let name = ps[0];
        ps.shift();
        argsByName[name] = ps.join("=");
    });

    return argsByName["p"];
}

exports.categoriesStringToSlugsArray = function(categories) {

    if (!categories) { return []; }

    return String(categories).split(",").map(s => ({ 
        slug: slugify(s.trim(), { strict: true, lower: true }),
        text: s.trim()
    }));

}

const genPrefix = (lang, absolute = false) => (lang === "en" ? "" : `/${lang}`) + (absolute ? "/" : "");
exports.genPrefix = genPrefix;

exports.getTranslations = function(edges, translateKey, midUrl = {}) {

    const translationsBase = {};
    edges.forEach(({ node }) => {
        const frontmatter = node.frontmatter;
        const lang = frontmatter.lang
        const tk = frontmatter.translateKey || frontmatter.template;

        if (!translationsBase[ tk ]) {
            translationsBase[ tk ] = {};
        }

        let url = genPrefix(lang, true) + (midUrl[ lang ] ? midUrl[ lang ] + "/" : "") + frontmatter.slug;
        if (url !== "/" && url[ url.length - 1] === "/") {
            url = url.substr(0, url.length - 1);
        }

        translationsBase[ tk ][ lang ] = { url, title: frontmatter.title };
    });

    return translationsBase[ translateKey ];
    
}

exports.linksFixes = function(text, lang = "en") {

    const searchUrl = lang === "en" ? "/publications/search?q=" : "/es/publicaciones/buscar?q=";

    return text
        .replace(/http:\/\/blog\.arrunada\.org\/wp-content\/uploads/igm, "/blog-uploads") //We have a copy of files in "/blog-uploads"
        .replace(/http:\/\/blog\.arrunada\.org\/\d{4}\/\d{2}\/(.*)\/\?lang=en/igm, "/blog/$1")
        .replace(/http:\/\/blog\.arrunada\.org\/\d{4}\/\d{2}\/([^(/|")]*)/igm, "/es/blog/$1")
        .replace(/http:\/\/blog\.arrunada\.org\/\?p=(\d*)(?:[^(/|")]*)?/igm, "/redirect/?id=$1&type=blogPost&lang=" + lang)
        .replace(/http:\/\/(?:www\.)?arrunada\.org\/PublicationsExpBusc\.aspx\?Id=(\d*)(?:[^(/|")]*)?/igm, "/redirect/?id=$1&type=publication&lang=" + lang)
        .replace(/http:\/\/(?:www\.)?arrunada\.org\/.*\/z(\d*).*(?:\.axd)/igm, "/redirect/?id=$1&type=publication&lang=" + lang)
        .replace(/files\/research/igm, "/files/research")
        .replace(/&submit\.x=\d{1,10}&submit\.y=\d{1,10}/igm, "")
        .replace(/http:\/\/(?:www\.)?arrunada\.org\/PublicationsBusc.aspx\?Buscar=/igm, searchUrl)
        .replace(/\/PublicationsBusc.aspx\?Buscar=/igm, searchUrl);
}





function linkIsExternal(href, origins = []) {
    let s = String(href) || "";
    
    if (s.indexOf("/") === 0) { return false; }

    for(let i = 0; i < origins.length; i += 1) {
        if (s.indexOf(origins[ i ]) === 0) {
            return false;
        }
    }

    return true;
}

function linkIsDownload(href) {
    if (!href) { return false; }

    let hrefParts = String(href || "").split("/"), 
        lastPart = "";

    for (var j = hrefParts.length - 1; j >= 0; j -= 1) {
        lastPart = hrefParts[ j ]
        if (lastPart) { break; }
    }

    if (lastPart.indexOf(".") > 0) { 
        return true;
    }

    return false;
}



exports.changeToLocalLinks = function(isHtml, navigateFn, origins = []) {

    if (!isHtml) { return; }

    let links = isHtml.getElementsByTagName("A"), href;
    for (let i = 0; i < links.length; i += 1) {
        href = links[ i ].getAttribute("href");
        if (
            ( linkIsExternal(href, origins) || linkIsDownload(href) )
            && !links[ i ].getAttribute("target")) {
            links[ i ].setAttribute("target", "_blank");
        }
    }

    isHtml.addEventListener("click", onClick);

    function onClick(ev) {
        const target = ev.target;
        if (target.nodeName !== "A") { return true; }
        let href = String(target.getAttribute("href"));

        if (!href || linkIsExternal(href, origins) || linkIsDownload(href)) { return true; }
    
        ev.stopPropagation();
        ev.preventDefault();
        navigateFn(href);
    }

    return onClick;
    
}

exports.getParamsFromQs = function(prms, defaults = {}, toLowerCase = false) {

    let argsByName = {};

    prms.forEach(prm => {
        let ps = prm.split("=");
        if (ps.length >= 2) {
            let name = ps[0];
            ps.shift();
            argsByName[toLowerCase ? String(name).toLowerCase() : name ] = ps.join("=");
        }
    });

    return Object.assign({}, defaults, argsByName);

}