import React, { Component } from "react"
import { graphql, navigate } from "gatsby"
import { getParamsFromQs, genPrefix } from "../components/helpers/helpers";

class Redirect extends Component {

    componentDidMount() {
        this.windowRef = window;

        const { location, data } = this.props;

        let prms = location.search.substr(1),
            argsByName = getParamsFromQs(prms.split("&")),
            url;

        if (argsByName.id && argsByName.type) {
            const translateKey = `${argsByName.type}-${argsByName.id}`;
            const candidates = data.data.edges.filter(({ node }) => node.frontmatter.translateKey === translateKey && node.frontmatter.lang === argsByName.lang);

            if (candidates.length) {
                const { frontmatter: { lang, slug } } = candidates[ 0 ].node;
                switch(argsByName.type) {
                    case "blogPost":
                            url = genPrefix(lang, true) + "blog/" + slug;
                            navigate(url, { replace: true });
                        break;
                    case "publication":
                        const pubsPageSlug = data.data.edges.filter(({ node }) => node.frontmatter.lang === lang && node.frontmatter.template === "publications");
                        url = genPrefix(lang, true) + pubsPageSlug[ 0 ].node.frontmatter.slug + "/" + slug;
                        navigate(url, { replace: true });
                        break;
                    default: 
                        console.log("Not known type", argsByName.type);
                        navigate("/404", { state: { originalUrl: location.pathname }, replace: true });
                }

            } else {
                navigate("/404", { state: { originalUrl: location.pathname } , replace: true });
            }
        } else {
            navigate("/404", { state: { originalUrl: location.pathname }, replace: true });
        }

    }

    render() {
        return (
            <div>
                Redirecting...
            </div>
        )
    }
}



export default Redirect

export const query = graphql`
    query RedirectQuery {
        data: allMarkdownRemark {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        title
                        menu
                        translateKey
                        categories
                    }
                }
            }
        }   
    }
`